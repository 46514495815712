import { DataInteractive as HeadlessDataInteractive } from "@headlessui/react";
import { Link as RemixLink, type LinkProps } from "@remix-run/react";
import React from "react";
import { Permission } from "../../foundation/types/permission";
import { useHasPermission } from "../../hooks/useHasPermission";

export const Link = React.forwardRef(function Link(
  props: { href: string | LinkProps["to"]; stopPropagation?: boolean } & Omit<
    LinkProps,
    "to"
  >,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const { stopPropagation, ...restProps } = props;
  return (
    <HeadlessDataInteractive>
      <RemixLink
        onClick={
          stopPropagation
            ? (e) => {
                e.stopPropagation();
              }
            : undefined
        }
        {...restProps}
        to={restProps.href}
        ref={ref}
        className={
          restProps.className ||
          "text-indigo-600 dark:text-indigo-500 hover:text-indigo-900 dark:hover:text-indigo-400"
        }
      />
    </HeadlessDataInteractive>
  );
});

export const SecureLink = React.forwardRef(function LinkFunc(
  props: {
    permission: Permission;
    store_id?: number;
    href: string | LinkProps["to"];
    stopPropagation?: boolean;
  } & Omit<LinkProps, "to">,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const { permission, store_id, ...rest } = props;

  const hasPermission = useHasPermission(permission, store_id);
  if (hasPermission) {
    return <Link {...rest} />;
  } else {
    return null;
  }
});
