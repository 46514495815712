import { useRouteLoaderData } from "@remix-run/react";
import { AppLoaderData } from "../routes/app";

export const useAppLoaderData = () => {
  const data = useRouteLoaderData<AppLoaderData>("routes/app");
  return data!.user;
};

export const useUserStoresLoaderData = () => {
  const data = useRouteLoaderData<AppLoaderData>("routes/app");
  return data!.user_stores;
};

export const useEmployeesLoaderData = () => {
  const data = useRouteLoaderData<AppLoaderData>("routes/app");
  return data!.all_employees;
};

export const usePositionsLoaderData = () => {
  const data = useRouteLoaderData<AppLoaderData>("routes/app");
  return data!.positions;
};

export const useTodoCategories = () => {
  const data = useRouteLoaderData<AppLoaderData>("routes/app");
  return data!.todoCategories;
};
