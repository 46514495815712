import { Permission } from "../../foundation/types/permission";
import { SessionUser } from "../../foundation/types/sessionUser";

export function hasPermission(
  permission: Permission,
  user: SessionUser,
  store_id?: number
) {
  switch (permission.module) {
    case "checklist": {
      return (
        (permission.permission & user.permissions_checklist) ===
          permission.permission &&
        (typeof store_id === "undefined" ||
          !user.store_specific_access ||
          user.store_ids.includes(store_id))
      );
    }
    case "core": {
      return (
        (permission.permission & user.permissions_core) ===
          permission.permission &&
        (typeof store_id === "undefined" ||
          !user.store_specific_access ||
          user.store_ids.includes(store_id))
      );
    }
    case "training": {
      return (
        (permission.permission & user.permissions_training) ===
          permission.permission &&
        (typeof store_id === "undefined" ||
          !user.store_specific_access ||
          user.store_ids.includes(store_id))
      );
    }
    default: {
      return false;
    }
  }
}
